<template lang="pug">
  page-layout(:title='$route.meta.title', :loading='loading || !bill')
    finance-form(
      :structure='formStructure',
      v-model='bill',
      :submit-label='submitLabel',
      modelName='bill',
      @save='saveBill',
      @cancel='$emit("cancel")',
      :v='$v',
      :loading='loading',
      :saving='saving',
      @input='$emit("input", $event)')
</template>

<script>
import {
  required,
  requiredIf,
  requiredUnless,
  decimal
} from "vuelidate/lib/validators";
import isNil from "ramda/src/isNil";
import isEmpty from "ramda/src/isEmpty";
import clone from "ramda/src/clone";
import FinanceForm from "@/views/finances/Form.vue";
import PageLayout from "@/views/common/PageLayout.vue";
import scrollToError from "@/components/utils/ScrollToError";

const lineItemRequiredValidation = requiredUnless(
  nestedModel =>
    isNil(nestedModel.description) || isEmpty(nestedModel.description)
);

export default {
  components: {
    FinanceForm,
    PageLayout
  },
  props: {
    value: Object,
    loading: Boolean,
    saving: Boolean,
    submitLabel: String
  },
  data() {
    return {
      bill: clone(this.value),
      formStructure: {
        resourceName: "Bill",
        keyFields: [
          {
            label: "Project",
            name: "project",
            type: "project",
            placeholder: "Select a project",
            required: true,
            help: "finance.bills.form.project"
          },
          { label: "Contact", name: "contact", type: "contact", required: true }
        ],
        detailFields: [
          {
            label: "Reference",
            name: "reference",
            type: "input",
            help: "finance.bills.form.reference"
          },
          {
            label: "Issued date",
            name: "issuedDate",
            type: "date",
            required: true
          },
          {
            label: "Due date",
            name: "dueDate",
            type: "date",
            placeholder: "Select a due date",
            min: "issuedDate",
            required: true
          },
          {
            label: "Currency",
            name: "currency",
            type: "currency",
            required: true
          },
          {
            label: "Exchange rate",
            name: "exchangeRate",
            type: "exchangeRate",
            required: true
          }
        ],
        lineItems: {
          type: "taxable",
          task: false,
          description: true
        }
      }
    };
  },
  validations: {
    bill: {
      project: {
        required
      },
      contact: {
        required
      },
      exchangeRate: {
        decimal,
        required: requiredIf(function notBaseCurrency(model) {
          return model.currency.id !== this.$store.state.defaultCurrency.id;
        })
      },
      issuedDate: {
        required
      },
      dueDate: {
        required
      },
      lineItems: {
        $each: {
          description: {
            required: requiredIf(
              nestedModel =>
                !(isNil(nestedModel.item) || isEmpty(nestedModel.item)) ||
                !(
                  isNil(nestedModel.quantity) || isEmpty(nestedModel.quantity)
                ) ||
                !(
                  isNil(nestedModel.unitCost) || isEmpty(nestedModel.unitCost)
                ) ||
                !(isNil(nestedModel.account) || isEmpty(nestedModel.account)) ||
                !(isNil(nestedModel.taxRate) || isEmpty(nestedModel.taxRate))
            )
          },
          quantity: {
            required: lineItemRequiredValidation,
            decimal
          },
          unitCost: {
            required: lineItemRequiredValidation,
            decimal
          },
          account: {
            required: lineItemRequiredValidation
          },
          taxRate: {
            required: lineItemRequiredValidation
          }
        }
      }
    }
  },
  watch: {
    value(newValue) {
      this.bill = clone(newValue);
    }
  },
  methods: {
    saveBill() {
      this.$v.bill.$touch();
      if (this.$v.bill.$invalid) {
        scrollToError(this);
      } else {
        this.$emit("save");
      }
    }
  }
};
</script>
